import { createI18n } from "vue-i18n";

const messages = {
  en: {
    userMenu__AccountSettings: "Account Settings",
    userMenu__Language: "Language",
    userMenu__ManageUsers: "Manage Users",
    userMenu__SignOut: "Sign Out",
    dashboard: "Dashboard",
    bookings: "Bookings",
    bookingCard__date: "Date",
    bookingCard__bookingNumber: "Booking No",
    bookingCard__participants: "Participants",
    bookingCard__bookedBy: "Booked By",
    bookingCard__meetingPoint: "Meeting Point",
    bookingCard__dropoffPoint: "Dropoff Point",
    bookingCard__purchaseDate: "Purchase Date",
    bookingCard__language: "Language",
    bookingCard__netPrice: "Net Price",
    bookingCard__customerPhone: "Customer Phone",
    bookingCard__customerEmail: "Customer Email",
    bookingCard__printVoucher: "Print voucher",
    bookingCard__gender: "Gender",
    bookingCard__name: "Name",
    bookingCard__surname: "Surname",
    bookingCard__passportNo: "Passport No",
    bookingCard__nationality: "Nationality",
    bookingCard__edit: "Edit",
    bookingCard__delete: "Delete",
    bookingCard__addPassenger: "Add passenger",
    bookingCard__showDetails: "Show details",
    bookingCard__hideDetails: "Hide details",
    bookingCard__cancellationReason: "Cancellation Reason",
    bookingCard__cancelReservation: "Cancel reservation",
    bookingCard__errorGeneral: "Something went wrong. Please try again.",
    bookingCard__informLater: "Will be inform later",
    cancel: "Vazgeç",
    gender__M: "Male",
    gender__F: "Female",
    confirmed: "Confirmed",
    canceled: "Canceled",
    formField_requiredError: "This is a required field",
    newPassengerModal__title: "Add New Passenger",
    newPassengerModal__genderLabel: "Gender",
    newPassengerModal__genderPlaceholder: "Select a Gender...",
    newPassengerModal__firstNameLabel: "First name",
    newPassengerModal__lastNameLabel: "Last name",
    newPassengerModal__passportNumberLabel: "Passport number",
    newPassengerModal__nationalityLabel: "Nationality",
    newPassengerModal__nationalityPlaceholder: "Select a Nationality...",
    editPassengerModal__title: "Edit Passenger",
    modal__discardCtaLabel: "Discard",
    modal__submitCtaLabel: "Submit",
    modal__pleaseWait: "Please wait...",
    modal__cancelCtaLabel: "Cancel",
    genericError__text:
      "We encountered an error and couldn't complete your request. Please try again a little later.",
    genericError__confirmButtonText: "Ok, got it!",
    confirmDialog__deletePassenger__text:
      "Are you sure you want to delete passenger? This action cannot be undone.",
    confirmDialog__deletePassenger__confirmButtonText: "Yes, delete it!",
    bookingsFilter__bookingNumber: "Booking Number",
    bookingsFilter__travelDate: "Travel Date",
    bookingsFilter__bookingStatus: "Booking Status",
    bookingsFilter__bookingStatusPlaceholder: "Filter by booking status",
    bookingsFilter__clearButtonText: "Clear all filters",
    bookingsFilter__notFoundText:
      "Sorry, we couldn't find any bookings for your search criteria. Please check your information and try again.",
    datePicker__today: "Today",
    datePicker__tomorrow: "Tomorrow",
    datePicker__next7Days: "Next 7 days",
    datePicker__from: "From",
    datePicker__to: "To",
    products: "Experiences",
    yourExperiences: "Your experiences",
    createAProduct: "Create an experience",
    productCard__status__active: "Active",
    productCard__status__inactive: "Offline",
    productCard__status__inprogress: "In progress",
    productCard__action__manage: "Manage",
    productCard__action__continue: "Continue",
    productCard__action__delete: "Delete",
    locationWizard__cta__label: "Add an address",
    locationWizard__cta__more__label: "Add another address",
    locationWizard__modal__add__title: "Add an address",
    locationWizard__modal__edit__title: "Edit the address",
    locationWizard__modal__infotip:
      "To make it as specific as possible, zoom in and drag the pin.",
    locationWizard__form__name__label: "Name of the address",
    locationWizard__form__desc__label:
      "Define the address using short, simple instructions.",
    googlemaps__autocomplete__placeholder: "Search a place",
    hotelRegionWizard__cta__label: "Add hotel region",
    hotelRegionWizard__serviceFee__cta__label: "Add service fee",
    hotelRegionWizard__serviceFee__modal__title: "Add service fee",
    hotelRegionWizard__serviceFee__form__type__label: "Service fee type",
    hotelRegionWizard__serviceFee__form__fee__label: "Service fee",
    hotelRegionWizard__serviceFee__per_person: "Per person",
    hotelRegionWizard__serviceFee__per_group: "Per group",
    hotelRegionWizard__desc:
      "Define a general area of hotels you provide service.",
    hotelRegionWizard__modal__add__title: "Add hotel region",
    hotelRegionWizard__modal__edit__title: "Edit the hotel region",
    hotelRegionWizard__form__region__label: "Define the geographic area",
    hotelRegionWizard__form__radius__label:
      "In how many km radius will you provide service?",
    hotelRegionWizard__region_radius: "Hotels within {0} km radius",
    hotelRegionWizard__infotip:
      "If region doesn't look right, drag the pin to adjust its location.",
    hotelRegionWizard__autocomplete__placeholder: "Search a region",
    airportWizard__cta__label: "Add an airport",
    airportWizard__modal__title: "Add an airport",
    airportWizard__autocomplete__placeholder: "Search an airport",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    viewOnMap: "View on map",
    enterALocation: "Enter a location",
    enterARegion: "Enter a region",
    addRegion: "Add region",
    describeInTurkish: "Describe in Turkish",
    describeInEnglish: "Describe in English",
    step: "Step",
    common__action__back: "Back",
    common__action__next: "Next",
    common__action__cancel: "Cancel",
    common__action__save: "Save",
    common__action__edit: "Edit",
    common__action__delete: "Delete",
    common__action__delete__popconfirm__title:
      "Are you sure you want to delete?",
    common__action__delete__popconfirm__confirm: "Yes, delete it!",
    common__action__delete__popconfirm__cancel: "Cancel",
    pickup__point__emptybox__description:
      "Meet with your customers at a specific point.",
    pickup__point__emptybox__cta__label: "Add an address",
    pickup__point__addModal__title: "Add a pick up point",
    pickup__point__editModal__title: "Edit the pick up point",
    point__modal__infotip:
      "To make it as specific as possible, zoom in and drag the pin.",
    pickup__point__form__name__label: "Pick up point's name",
    pickup__point__form__desc__label:
      "Define the meeting place using short, simple instructions.",
    dropoff__point__emptybox__description:
      "Drop off your customers at a specific place.",
    dropoff__point__emptybox__cta__label: "Add a drop off point",
    dropoff__point__addModal__title: "Add a drop off point",
    dropoff__point__editModal__title: "Edit the drop off place",
    dropoff__point__form__name__label: "Drop off point's name",
    dropoff__point__form__desc__label:
      "Define the drop off point using short, simple instructions.",

    hotel__pickup__regions__emptybox__description:
      "Pick up your customers from hotels locates in specific region.",
    hotel__pickup__regions__emptybox__cta__label: "Add hotel pick up region",
    hotel__pickup__regions__addModal__title: "Add hotel pick up region",
    hotel__pickup__regions__addModal__step1__label:
      "Define the geographic area for hotel pickups",
    hotel__pickup__regions__addModal__step2__label:
      "In how many km radius will you provide hotel pick-up service?",
    hotel__pickup__regions__editModal__title: "Edit hotel pick up region",
    hotel__pickup__regions__editModal__step1__label:
      "In how many km radius will you provide hotel pick-up service?",

    hotel__dropoff__regions__emptybox__description:
      "Drop off your customers to hotels locates in specific region.",
    hotel__dropoff__regions__emptybox__cta__label: "Add hotel drop-off region",
    hotel__dropoff__regions__addModal__title: "Add hotel drop-off region",
    hotel__dropoff__regions__addModal__step1__label:
      "Define the geographic area for hotel drop offs",
    hotel__dropoff__regions__addModal__step2__label:
      "In how many km radius will you provide hotel drop-off service?",
    hotel__dropoff__regions__editModal__title: "Edit hotel drop-off region",
    hotel__dropoff__regions__editModal__step1__label:
      "In how many km radius will you provide hotel drop-off service?",

    add__service__fee: "Add service fee",
    serviceFee__perPerson: "Per person",
    serviceFee__perGroup: "Per group",
    serviceFee__type: "Service fee type",
    serviceFee: "Service fee",

    common_cta_cancel: "Cancel",
    common_cta_back: "Back",
    common_cta_next: "Next",
    common_cta_save: "Save",
    common_cta_delete: "Delete",
    common_cta_edit: "Edit",
    common_cta_add: "Add",
    common_cta_try_again: "Try again",

    common_locale_tr: "Turkish",
    common_locale_en: "English",

    participantType__passenger: "Passenger",
    participantType__elderly: "Elderly",
    participantType__adult: "Adult",
    participantType__youth: "Youth",
    participantType__student: "Student",
    participantType__child: "Child",
    participantType__infant: "Infant",

    product_status__active: "Active",
    product_status__inactive: "Inactive",

    common_error_general_title: "Something went wrong!",
    common_error_general_subtitle: "We encountered an error. Please try again.",
    common_in_turkish: "In Turkish",
    common_in_english: "In English",
  },
  tr: {
    userMenu__AccountSettings: "Hesap Ayarları",
    userMenu__Language: "Dil",
    userMenu__ManageUsers: "Kullanıcıları Yönet",
    userMenu__SignOut: "Çıkış Yap",
    dashboard: "Dashboard",
    bookings: "Rezervasyonlar",
    bookingCard__date: "Tarih",
    bookingCard__bookingNumber: "Rezervasyon No",
    bookingCard__participants: "Katılımcılar",
    bookingCard__bookedBy: "Satın Alan",
    bookingCard__meetingPoint: "Buluşma Yeri",
    bookingCard__dropoffPoint: "Varış Yeri",
    bookingCard__purchaseDate: "Satın Alma Tarihi",
    bookingCard__language: "Dil",
    bookingCard__netPrice: "Net Tutar",
    bookingCard__customerPhone: "Müşteri Telefonu",
    bookingCard__customerEmail: "Müşteri E-posta",
    bookingCard__printVoucher: "Vocuher indir",
    bookingCard__cancellationReason: "İptal Nedeni",
    bookingCard__cancelReservation: "Rezervasyonu iptal et",
    bookingCard__errorGeneral: "Bir şeyler ters gitti. Lütfen tekrar deneyin.",
    bookingCard__gender: "Cinsiyet",
    bookingCard__name: "Ad",
    bookingCard__surname: "Soyad",
    bookingCard__passportNo: "Pasaport No",
    bookingCard__nationality: "Ülke",
    bookingCard__edit: "Düzenle",
    bookingCard__delete: "Sil",
    bookingCard__addPassenger: "Yolcu ekle",
    bookingCard__showDetails: "Daha fazla göster",
    bookingCard__hideDetails: "Daha az göster",
    bookingCard__informLater: "Daha sonra belirtilecek",
    gender__M: "Erkek",
    gender__F: "Kadın",
    confirmed: "Onaylandı",
    canceled: "İptal edildi",
    formField_requiredError: "Bu alan boş geçilemez",
    newPassengerModal__title: "Yolcu Ekle",
    newPassengerModal__genderLabel: "Cinsiyet",
    newPassengerModal__genderPlaceholder: "Bir cisiyet seç...",
    newPassengerModal__firstNameLabel: "Ad",
    newPassengerModal__lastNameLabel: "Soyad",
    newPassengerModal__passportNumberLabel: "Pasaport numarası",
    newPassengerModal__nationalityLabel: "Ülke",
    newPassengerModal__nationalityPlaceholder: "Bir ülke seç...",
    editPassengerModal__title: "Yolcu Düzenle",
    modal__discardCtaLabel: "Vazgeç",
    modal__submitCtaLabel: "Kaydet",
    modal__cancelCtaLabel: "Vazgeç",
    modal__pleaseWait: "Lütfen bekleyin...",
    genericError__text:
      "İşleminiz gerçekleştirirken bir hata ile karşılaştık. Lütfen kısa bir süre sonra tekrar deneyin.",
    genericError__confirmButtonText: "Tamam, anladım!",
    confirmDialog__deletePassenger__text:
      "Bu yolcuyu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
    confirmDialog__deletePassenger__confirmButtonText: "Evet, sil!",
    bookingsFilter__bookingNumber: "Rezervasyon No",
    bookingsFilter__travelDate: "Seyahat Tarihi",
    bookingsFilter__bookingStatus: "Rezervasyon Durumu",
    bookingsFilter__bookingStatusPlaceholder: "Tümü",
    bookingsFilter__clearButtonText: "Tüm filtreleri temizle",
    bookingsFilter__notFoundText:
      "Üzgünüz, arama kriterlerinize uygun rezervasyon bulamadık. Lütfen bilgilerinizi kontrol edip tekrar deneyin.",
    datePicker__today: "Bugün",
    datePicker__tomorrow: "Yarın",
    datePicker__next7Days: "Sonraki 7 gün",
    datePicker__from: "Başlangıç",
    datePicker__to: "Bitiş",
    products: "Deneyimler",
    yourExperiences: "Deneyimleriniz",
    createAProduct: "Deneyim oluştur",
    productCard__status__active: "Yayında",
    productCard__status__inactive: "Yayında değil",
    productCard__status__inprogress: "Yapım aşamasında",
    productCard__action__manage: "Yönet",
    productCard__action__continue: "Devam et",
    productCard__action__delete: "Sil",
    locationWizard__cta__label: "Bir adres ekle",
    locationWizard__cta__more__label: "Bir adres daha ekle",
    locationWizard__modal__add__title: "Bir adres ekle",
    locationWizard__modal__edit__title: "Adresi düzenle",
    locationWizard__modal__infotip:
      "Konum doğru değilse yakınlaştırıp raptiyeyi doğru yere sürükleyebilirsin.",
    locationWizard__form__name__label: "Adresin adı",
    locationWizard__form__desc__label:
      "Kısa, basit talimatlar kullanarak adresi tanımlayın.",
    googlemaps__autocomplete__placeholder: "Bir yer ara",
    hotelRegionWizard__cta__label: "Otel bölgesi ekle",
    hotelRegionWizard__serviceFee__cta__label: "Servis ücreti ekle",
    hotelRegionWizard__serviceFee__modal__title: "Servis ücreti ekle",
    hotelRegionWizard__serviceFee__form__type__label: "Ücret tipi",
    hotelRegionWizard__serviceFee__form__fee__label: "Servis ücreti",
    hotelRegionWizard__serviceFee__per_person: "Kişi başı",
    hotelRegionWizard__serviceFee__per_group: "Grup başı",
    hotelRegionWizard__desc:
      "Hizmet verdiğiniz otellerin genel bir alanını tanımlayın.",
    hotelRegionWizard__modal__add__title: "Otel bölgesi ekle",
    hotelRegionWizard__modal__edit__title: "Otel bölgesini düzenle",
    hotelRegionWizard__form__region__label: "Hizmet sunacağınız bölgeyi girin",
    hotelRegionWizard__form__radius__label:
      "Kaç km çapındaki alanda hizmet vereceksiniz?",
    hotelRegionWizard__region_radius: "{0} km çapındaki oteller",
    hotelRegionWizard__infotip:
      "Bölge doğru görünmüyorsa konumunu ayarlamak için raptiyeyi sürükleyin.",
    hotelRegionWizard__autocomplete__placeholder: "Bir bölge arayın",
    airportWizard__cta__label: "Havalimanı ekle",
    airportWizard__modal__title: "Havalimanı ekle",
    airportWizard__autocomplete__placeholder: "Bir havalimanı arayın",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    viewOnMap: "Haritada görüntüle",
    enterALocation: "Bir konum girin",
    enterARegion: "Bölge girin",
    addRegion: "Bölge ekle",
    describeInTurkish: "Türkçe açıklama ekle",
    describeInEnglish: "İngilizce açıklama ekle",
    step: "Adım",
    common__action__back: "Geri",
    common__action__next: "Devam et",
    common__action__cancel: "Vazgeç",
    common__action__save: "Kaydet",
    common__action__edit: "Düzenle",
    common__action__delete: "Sil",
    common__action__delete__popconfirm__title:
      "Silmek istediğinize emin misiniz?",
    common__action__delete__popconfirm__confirm: "Evet, sil!",
    common__action__delete__popconfirm__cancel: "Vazgeç",
    pickup__point__emptybox__description:
      "Müşterilerinizle belirli bir adreste buluşun.",
    pickup__point__emptybox__cta__label: "Bir adres ekle",
    pickup__point__addModal__title: "Bir adres ekle",
    pickup__point__editModal__title: "Adresi düzenle",
    point__modal__infotip:
      "Konum doğru değilse yakınlaştırıp raptiyeyi doğru yere sürükleyebilirsin.",
    pickup__point__form__name__label: "Adresin adı",
    pickup__point__form__desc__label:
      "Kısa, basit talimatlar kullanarak adresi tanımlayın.",
    dropoff__point__emptybox__description:
      "Müşterilerinizi belirli bir adrese bırakın.",
    dropoff__point__emptybox__cta__label: "Bir adres ekle",
    dropoff__point__addModal__title: "Bir adres ekle",
    dropoff__point__editModal__title: "Adresi düzenle",
    dropoff__point__form__name__label: "Adresin adı",
    dropoff__point__form__desc__label:
      "Kısa, basit talimatlar kullanarak adresi tanımlayın.",

    hotel__pickup__regions__emptybox__description:
      "Müşterilerinizi belirli bir bölgede bulunan otellerden alın.",
    hotel__pickup__regions__emptybox__cta__label: "Otel bölgesi eke",
    hotel__pickup__regions__addModal__title: "Otel bölgesi ekle",
    hotel__pickup__regions__addModal__step1__label:
      "Hizmet sunacağınız bölgeyi girin",
    hotel__pickup__regions__addModal__step2__label:
      "Kaç km çapındaki alanda hizmet vereceksiniz?",
    hotel__pickup__regions__editModal__title: "Otel bölgesini düzenle",
    hotel__pickup__regions__editModal__step1__label:
      "Kaç km çapındaki alanda hizmet vereceksiniz?",

    hotel__dropoff__regions__emptybox__description:
      "Müşterilerinizi belirli bir bölgede bulunan otellere bırakın.",
    hotel__dropoff__regions__emptybox__cta__label: "Otel bölgesi ekle",
    hotel__dropoff__regions__addModal__title: "Otel bölgesi ekle",
    hotel__dropoff__regions__addModal__step1__label:
      "Hizmet sunacağınız bölgeyi girin",
    hotel__dropoff__regions__addModal__step2__label:
      "Kaç km çapındaki alanda hizmet vereceksiniz?",
    hotel__dropoff__regions__editModal__title: "Otel bölgesini düzenle",
    hotel__dropoff__regions__editModal__step1__label:
      "Kaç km çapındaki alanda hizmet vereceksiniz?",

    add__service__fee: "Servis ücreti ekle",
    serviceFee__perPerson: "Kişi başı",
    serviceFee__perGroup: "Grup başı",
    serviceFee__type: "Servis ücreti tipi",
    serviceFee: "Servis ücreti",

    common_cta_cancel: "Vazgeç",
    common_cta_back: "Geri",
    common_cta_next: "Sonraki",
    common_cta_save: "Kaydet",
    common_cta_delete: "Sil",
    common_cta_edit: "Düzenle",
    common_cta_add: "Ekle",
    common_cta_try_again: "Tekrar dene",

    common_locale_tr: "Türkçe",
    common_locale_en: "İngilizce",

    participantType__passenger: "Yolcu",
    participantType__elderly: "Yaşlı",
    participantType__adult: "Yetişkin",
    participantType__youth: "Genç",
    participantType__student: "Öğrenci",
    participantType__child: "Çocuk",
    participantType__infant: "Bebek",

    product_status__active: "Yayında",
    product_status__inactive: "Yayında değil",

    common_error_general_title: "Something went wrong!",
    common_error_general_subtitle: "We encountered an error. Please try again.",
    common_in_turkish: "Türkçe",
    common_in_english: "İngilizce",
  },
};
const lang = localStorage.getItem("lang");
const i18n = createI18n({
  legacy: false,
  locale: lang === null ? "tr" : lang,
  globalInjection: true,
  messages,
});

export default i18n;
