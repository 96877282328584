import ApiService from "@/core/services/ApiService";
import { useAddDay } from "@/core/composables/addDay";
import { Mutations, Actions } from "@/store/enums/ProductCalendarStoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import type {
  ParticipantType,
  Price,
  TranslatedData,
} from "@/core/types/shared";

interface EventResource {
  pnr: string;
  status: "active" | "inactive" | "sold_out";
  startDate: string;
  endDate: string;
  soldSeat: number;
}

export interface Event {
  pnr: string;
  departureTimes: { id: number; start: string; end: string }[];
  startDate: string;
  endDate: string;
  freeCancellationDuration: number;
  cutOffDuration: string;
  serviceDescription: TranslatedData;
  quota: number;
  soldSeat: number;
  groupPrice?: Price;
  groupPriceThreshold?: number;
  perPersonPrices: { participant: ParticipantType; price: Price }[];
  accommodationPrices?: {
    adult: number;
    child: number;
    infant: number;
    price: Price;
  }[];
  minimumParticipantNumber: number;
  minimumParticipantOption: "every_booking" | "first_booking";
  status: "active" | "inactive" | "sold_out";
}

const availableEventUI = {
  title: "Müsait",
  classNames: ["fw-bolder", "p-1"],
  backgroundColor: "var(--el-color-success-light-7)",
  borderColor: "var(--el-color-success-light-7)",
  textColor: "var(--el-color-success-dark-2)",
};

const partialAvailableEventUI = {
  title: "Kısmi Müsait",
  classNames: ["fw-bolder", "p-1", "fc-partially-available"],
  borderColor: "var(--el-color-success-light-7)",
  textColor: "var(--el-color-success-dark-2)",
};

const soldOutEventUI = {
  title: "Tükendi",
  classNames: ["fw-bolder", "p-1"],
  backgroundColor: "var(--el-color-danger-light-7)",
  borderColor: "var(--el-color-danger-light-7)",
  textColor: "var(--el-color-danger-dark-2)",
};

const notForSaleEventUI = {
  title: "Satışa Kapalı",
  classNames: ["fw-bolder", "p-1", "fc-not-for-sale"],
  textColor: "#909399",
  borderColor: "var(--el-color-info-light-5)",
};

export interface StoreInfo {
  newAvailabilitiesPNR: string[];
  events: EventResource[];
  selectedEvent?: Event;
}

@Module
export default class ProductCalendarModule
  extends VuexModule
  implements StoreInfo
{
  newAvailabilitiesPNR: string[] = [];
  events: EventResource[] = [];
  selectedEvent: Event | undefined = undefined;

  get getEvents() {
    return this.events.map((event) => {
      return {
        id: event.pnr,
        start: event.startDate,
        end: useAddDay({ date: event.endDate }).date,
        allDay: true,
        display: "block",
        ...(event.status === "inactive" && notForSaleEventUI),
        ...(event.status === "sold_out" && soldOutEventUI),
        ...(event.status === "active" &&
          event.soldSeat > 0 &&
          partialAvailableEventUI),
        ...(event.status === "active" &&
          event.soldSeat === 0 &&
          availableEventUI),
      };
    });
  }

  get getSelectedEvent(): Event | undefined {
    return this.selectedEvent;
  }

  @Mutation
  [Mutations.SET_NEW_AVAILABILITIES_PNR](payload: string[]): void {
    this.newAvailabilitiesPNR = payload;
  }

  @Mutation
  [Mutations.SET_EVENTS](payload: EventResource[]): void {
    this.events = [...payload];
  }

  @Mutation
  [Mutations.SET_EVENT](payload: Event): void {
    this.selectedEvent = { ...payload };
  }

  @Action
  [Actions.INITIALIZE_NEW_EVENTS]({
    productID,
    dateFrom,
    dateTo,
  }: {
    productID: number;
    dateFrom: string;
    dateTo: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { dateFrom, dateTo };
      ApiService.post(`products/${productID}/calendar/events`, payload)
        .then(({ data }: { data: string[] }) => {
          this.context.commit(Mutations.SET_NEW_AVAILABILITIES_PNR, data);
          resolve(data);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot initialize new events");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_NEW_EVENTS]({
    productID,
    options,
  }: {
    productID: number;
    options: object;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {
        events: this.newAvailabilitiesPNR,
        ...options,
      };
      ApiService.put(`products/${productID}/calendar/events`, payload)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot set new events");
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_EVENTS]({
    productID,
    dateFrom,
    dateTo,
  }: {
    productID: number;
    dateFrom: string;
    dateTo: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { dateFrom, dateTo };
      ApiService.get(
        `products/${productID}/calendar`,
        `events?dateFrom=${dateFrom}&dateTo=${dateTo}`
      )
        .then(({ data }: { data: EventResource[] }) => {
          this.context.commit(Mutations.SET_EVENTS, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load events");
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_EVENT]({ productID, pnr }: { productID: number; pnr: string }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`products/${productID}/calendar`, `events/${pnr}`)
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENT_QUOTA]({
    productID,
    pnr,
    quota,
  }: {
    productID: number;
    pnr: string;
    quota: number;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { quota };
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/quota`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENT_CUT_OFF]({
    productID,
    pnr,
    cutOff,
  }: {
    productID: number;
    pnr: string;
    cutOff: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { duration: cutOff };
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/cut-off-duration`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENT_FREE_CANCELATION]({
    productID,
    pnr,
    duration,
  }: {
    productID: number;
    pnr: string;
    duration: number;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { duration };
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/free-cancellation-duration`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENT_SERVICE_SUMMARY]({
    productID,
    pnr,
    summary,
  }: {
    productID: number;
    pnr: string;
    summary: number;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { summary };
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/service-summary`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENT_PRICE]({
    productID,
    pnr,
    price,
  }: {
    productID: number;
    pnr: string;
    price: object;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { ...price };
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/price`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENT_ACCOMMODATION_PRICE]({
    productID,
    pnr,
    prices,
  }: {
    productID: number;
    pnr: string;
    prices: any;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { prices: [...prices] };
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/accommodation-price`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENT_DEPARTURE_TIME]({
    productID,
    pnr,
    departureTimeID,
    departureTime,
  }: {
    productID: number;
    pnr: string;
    departureTimeID: number;
    departureTime: { start: string; end: string };
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { ...departureTime };
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/departure-times/${departureTimeID}`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EVENT_DEPARTURE_TIME]({
    productID,
    pnr,
    departureTimeID,
  }: {
    productID: number;
    pnr: string;
    departureTimeID: number;
  }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `products/${productID}/calendar/events/${pnr}/departure-times/${departureTimeID}`
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_EVENT_DEPARTURE_TIME]({
    productID,
    pnr,
    departureTime,
  }: {
    productID: number;
    pnr: string;
    departureTime: { start: string; end: string };
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { ...departureTime };
      ApiService.post(
        `products/${productID}/calendar/events/${pnr}/departure-times`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot load event");
          reject();
        });
    });
  }

  @Action
  [Actions.CLOSE_EVENT_FOR_SALE]({
    productID,
    pnr,
  }: {
    productID: number;
    pnr: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {};
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/close`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot close event for sale");
          reject();
        });
    });
  }

  @Action
  [Actions.OPEN_EVENT_FOR_SALE]({
    productID,
    pnr,
  }: {
    productID: number;
    pnr: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {};
      ApiService.put(
        `products/${productID}/calendar/events/${pnr}/open`,
        payload
      )
        .then(({ data }: { data: Event[] }) => {
          this.context.commit(Mutations.SET_EVENT, data);
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot open event for sale");
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EVENT]({
    productID,
    pnr,
  }: {
    productID: number;
    pnr: string;
  }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`products/${productID}/calendar/events/${pnr}`)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot delete event");
          reject();
        });
    });
  }

  @Action
  [Actions.DUPLICATE_EVENT]({
    productID,
    pnr,
    targetDate,
  }: {
    productID: number;
    pnr: string;
    targetDate: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = { targetDate };
      ApiService.post(
        `products/${productID}/calendar/events/${pnr}/duplicate`,
        payload
      )
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot duplicate event");
          reject();
        });
    });
  }

  @Action
  [Actions.CLOSE_EVENTS_FOR_SALE_IN_DATE_RANGE]({
    productID,
    dateFrom,
    dateTo,
  }: {
    productID: number;
    dateFrom: string;
    dateTo: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {
        from: dateFrom,
        to: dateTo,
      };
      ApiService.put(`products/${productID}/calendar/close`, payload)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot close events for sale in date range");
          reject();
        });
    });
  }

  @Action
  [Actions.OPEN_EVENTS_FOR_SALE_IN_DATE_RANGE]({
    productID,
    dateFrom,
    dateTo,
  }: {
    productID: number;
    dateFrom: string;
    dateTo: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {
        from: dateFrom,
        to: dateTo,
      };
      ApiService.put(`products/${productID}/calendar/open`, payload)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot open events for sale in date range");
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EVENTS_FOR_SALE_IN_DATE_RANGE]({
    productID,
    dateFrom,
    dateTo,
  }: {
    productID: number;
    dateFrom: string;
    dateTo: string;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {
        from: dateFrom,
        to: dateTo,
      };
      ApiService.put(`products/${productID}/calendar/delete`, payload)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot delete events for sale in date range");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENTS_PRICE_IN_DATE_RANGE]({
    productID,
    dateFrom,
    dateTo,
    price,
  }: {
    productID: number;
    dateFrom: string;
    dateTo: string;
    price: object;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {
        ...price,
        dateRange: {
          from: dateFrom,
          to: dateTo,
        },
      };
      ApiService.put(`products/${productID}/calendar/price`, payload)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("[ERROR]: Cannot set events price in date range");
          reject();
        });
    });
  }

  @Action
  [Actions.SET_EVENTS_ACCOMMODATION_PRICE_IN_DATE_RANGE]({
    productID,
    dateFrom,
    dateTo,
    prices,
  }: {
    productID: number;
    dateFrom: string;
    dateTo: string;
    prices: any;
  }) {
    return new Promise((resolve, reject) => {
      const payload: any = {
        prices: [...prices],
        dateRange: {
          from: dateFrom,
          to: dateTo,
        },
      };
      ApiService.put(
        `products/${productID}/calendar/accommodation-price`,
        payload
      )
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log(
            "[ERROR]: Cannot set events acommodation price in date range"
          );
          reject();
        });
    });
  }
}
