enum Actions {
  // action types
  LOAD_SUPPLIER = "supplier__loadSupplier",
}

enum Mutations {
  // mutation types
  SET_SUPPLIER = "supplier__setSupplier",
}

export { Actions, Mutations };
