import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import router from "@/router";
import { Mutations } from "@/store/enums/StoreEnums";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Accept-Language"] =
      window.localStorage.getItem("lang");
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios
        .get(`${resource}/${slug}`)
        .then((response) => resolve(response))
        .catch((error) => {
          if (error.response.status == 403) {
            store.commit(Mutations.PURGE_AUTH);
            router.push({ name: "sign-in" });
          } else if (error.response.status == 404) {
            router.push({ name: "404" });
          } else {
            reject(error);
          }
        });
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios
        .post(`${resource}`, params)
        .then((response) => resolve(response))
        .catch((error) => {
          if (error.response.status == 403) {
            store.commit(Mutations.PURGE_AUTH);
            router.push({ name: "sign-in" });
          } else if (error.response.status == 404) {
            router.push({ name: "404" });
          } else {
            reject(error);
          }
        });
    });
    // return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios
        .put(`${resource}/${slug}`, params)
        .then((response) => resolve(response))
        .catch((error) => {
          if (error.response.status == 403) {
            store.commit(Mutations.PURGE_AUTH);
            router.push({ name: "sign-in" });
          } else if (error.response.status == 404) {
            router.push({ name: "404" });
          } else {
            reject(error);
          }
        });
    });
    // return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios
        .delete(resource)
        .then((response) => resolve(response))
        .catch((error) => {
          if (error.response.status == 403) {
            store.commit(Mutations.PURGE_AUTH);
            router.push({ name: "sign-in" });
          } else if (error.response.status == 404) {
            router.push({ name: "404" });
          } else {
            reject(error);
          }
        });
    });
    // return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
