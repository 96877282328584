import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutations as ProductStoreMutations } from "@/store/enums/ProductStoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
      {
        path: "/bookings",
        name: "bookings",
        component: () => import("@/views/booking/Bookings.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
      {
        path: "/bookings/:id",
        name: "booking-detail",
        component: () => import("@/views/booking/BookingDetail.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
      {
        path: "/operations/:id",
        name: "operation-detail",
        component: () => import("@/views/operation/OperationDetail.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/SupplierSettings.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/views/Products.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Wizard.vue"),
    children: [
      {
        path: "/products/:id/create",
        name: "product-create",
        component: () => import("@/views/product/ProductWizard.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
      {
        path: "/product/:id/availability",
        name: "product-calendar",
        component: () => import("@/views/product/ProductCalendar.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
      {
        path: "/onboarding",
        name: "supplier-onboarding",
        component: () => import("@/views/Onboarding.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ACTIVE") {
            router.push({ name: "dashboard" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Standard.vue"),
    children: [
      {
        path: "/products/:id",
        name: "product-details",
        component: () => import("@/views/product/ProductDetails.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "IN_REVIEW") {
            router.push({ name: "supplier-onboarding-success" });
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/SignUp.vue"),
      },
      {
        path: "/email-confirmation",
        name: "email-confirmation",
        component: () => import("@/views/authentication/EmailConfirmation.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/onboarding-success",
        name: "supplier-onboarding-success",
        component: () => import("@/views/OnboardingSuccess.vue"),
        beforeEnter: async () => {
          if (store.getters.currentUser.supplierStatus === "ONBOARDING") {
            router.push({ name: "supplier-onboarding" });
          } else if (store.getters.currentUser.supplierStatus === "ACTIVE") {
            router.push({ name: "dashboard" });
          }
        },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (from) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.commit(ProductStoreMutations.RESET_PRODUCT);
  await store.dispatch(Actions.VERIFY_AUTH, {
    api_token: JwtService.getToken(),
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
