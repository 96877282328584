import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/ProductWizardStoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import type { ProductTheme, ProductInProgress } from "src/core/types/product";

interface Step {
  number: number;
  title: string;
}

export interface StoreInfo {
  productID: number | undefined;
  product?: ProductInProgress;
  steps: Step[];
  activeStep: number;
  lastCompletedStep: number;
  productTheme?: ProductTheme;
}

@Module
export default class ProductWizardModule
  extends VuexModule
  implements StoreInfo
{
  productID: number | undefined = undefined;
  product: ProductInProgress | undefined = undefined;
  activeStep = 1;
  lastCompletedStep = 0;
  productTheme: ProductTheme | undefined = undefined;
  steps = [
    {
      number: 1,
      title: "step__service_type",
    },
    {
      number: 2,
      title: "step__departure_point",
    },
    {
      number: 3,
      title: "step__attractions",
    },
    {
      number: 4,
      title: "step__title",
    },
    {
      number: 5,
      title: "step__summary",
    },
    {
      number: 6,
      title: "step__features",
    },
    {
      number: 7,
      title: "step__guiding",
    },
    {
      number: 8,
      title: "step__highlights",
    },
    {
      number: 9,
      title: "step__full_description",
    },
    {
      number: 10,
      title: "step__includes",
    },
    {
      number: 11,
      title: "step__excludes",
    },
    {
      number: 12,
      title: "step__importants",
    },
    {
      number: 13,
      title: "step__photos",
    },
    {
      number: 14,
      title: "step__group_size",
    },
    {
      number: 15,
      title: "step__cancellation",
    },
    {
      number: 16,
      title: "step__participants",
    },
    {
      number: 17,
      title: "step__duration",
    },
    {
      number: 18,
      title: "step__showcase_price",
    },
    {
      number: 19,
      title: "step__meeting",
    },
    {
      number: 20,
      title: "step__submit",
    },
  ];

  get getActiveStep() {
    return this.activeStep;
  }

  get getLastCompletedStep() {
    return this.lastCompletedStep;
  }

  get getSteps() {
    if (this.product?.theme === "entrance_ticket") {
      return this.steps.filter((el) => el.title !== "step__service_type");
    }
    return this.steps;
  }

  get getProgressPercentage() {
    return (this.lastCompletedStep * 100) / this.steps.length;
  }

  get getProductThemes() {
    return this.productTheme;
  }

  get getNumberOfStepsToGo() {
    return this.steps.length - this.lastCompletedStep;
  }

  get getDraftProduct() {
    return this.product;
  }

  @Mutation
  [Mutations.SET_PRODUCT_ID](payload: number): void {
    this.productID = payload;
  }

  @Mutation
  [Mutations.SET_PRODUCT](payload: ProductInProgress): void {
    this.product = payload;
  }

  @Mutation
  [Mutations.SET_ACTIVE_STEP](payload: number): void {
    this.activeStep = payload;
  }

  @Mutation
  [Mutations.SET_LAST_COMPLETED_STEP](payload: number): void {
    this.lastCompletedStep = payload;
  }

  @Mutation
  [Mutations.SET_PRODUCT_THEME](payload: ProductTheme): void {
    this.productTheme = payload;
  }

  @Action
  [Actions.GOTO_NEXT_STEP]() {
    if (this.activeStep <= this.lastCompletedStep) {
      this.context.commit(Mutations.SET_ACTIVE_STEP, this.activeStep + 1);
      return;
    }

    const payload: any = {
      step: this.activeStep,
    };

    this.context.commit(Mutations.SET_LAST_COMPLETED_STEP, this.activeStep);
    this.context.commit(Mutations.SET_ACTIVE_STEP, this.activeStep + 1);

    ApiService.put(`products/${this.productID}/last-completed-step`, payload);
  }

  @Action
  [Actions.LOAD_PRODUCT_THEME]() {
    ApiService.get(`products/${this.productID}/theme`)
      .then(({ data }: { data: { theme: ProductTheme } }) => {
        this.context.commit(Mutations.SET_PRODUCT_THEME, data.theme);
      })
      .catch(() => {
        console.log("**** cannot load product theme ****");
      });
  }

  @Action
  [Actions.LOAD_DRAFT_PRODUCT](payload: number) {
    ApiService.get(`products`, `${payload}`)
      .then(({ data }: { data: ProductInProgress }) => {
        this.context.commit(Mutations.SET_PRODUCT, data);
        const { lastCompletedStep } = data;
        this.context.commit(
          Mutations.SET_LAST_COMPLETED_STEP,
          lastCompletedStep
        );
        this.context.commit(Mutations.SET_ACTIVE_STEP, lastCompletedStep + 1);
      })
      .catch(() => {
        console.log("**** [ERROR] cannot load product ****");
      });
  }
}
