enum Actions {
  INITIALIZE_NEW_EVENTS = "initializeNewEvents",
  SET_NEW_EVENTS = "setNewEvents",
  LOAD_EVENTS = "loadEvents",
  LOAD_EVENT = "loadEvent",
  SET_EVENT_QUOTA = "updateEventQuota",
  SET_EVENT_CUT_OFF = "updateEventCutOff",
  SET_EVENT_FREE_CANCELATION = "updateEventFreeCancellation",
  SET_EVENT_SERVICE_SUMMARY = "updateEventServiceSummary",
  SET_EVENT_PRICE = "updateEventPrice",
  SET_EVENT_ACCOMMODATION_PRICE = "updateEventAccommodationPrice",
  SET_EVENT_DEPARTURE_TIME = "updateEventDepartureTime",
  DELETE_EVENT_DEPARTURE_TIME = "deleteEventDepartureTime",
  ADD_EVENT_DEPARTURE_TIME = "addEventDepartureTime",
  CLOSE_EVENT_FOR_SALE = "closeEventForSale",
  OPEN_EVENT_FOR_SALE = "openEventForSale",
  DELETE_EVENT = "deleteEvent",
  DUPLICATE_EVENT = "duplicateEvent",
  CLOSE_EVENTS_FOR_SALE_IN_DATE_RANGE = "closeEventsForSaleInDateRange",
  OPEN_EVENTS_FOR_SALE_IN_DATE_RANGE = "openEventsForSaleInDateRange",
  DELETE_EVENTS_FOR_SALE_IN_DATE_RANGE = "deleteEventsForSaleInDateRange",
  SET_EVENTS_PRICE_IN_DATE_RANGE = "updateEventsPriceInDateRange",
  SET_EVENTS_ACCOMMODATION_PRICE_IN_DATE_RANGE = "updateEventsAccommodationPriceInDateRange",
}

enum Mutations {
  SET_NEW_AVAILABILITIES_PNR = "setNewAvailabilitiesPNR",
  SET_EVENTS = "setEvents",
  SET_EVENT = "setEvent",
}

export { Actions, Mutations };
