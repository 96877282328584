import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/SupplierOnboardingStoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import type { Supplier } from "@/core/types/supplier";

interface Step {
  number: number;
  title: string;
}

export interface StoreInfo {
  supplier: Supplier | undefined;
  steps: Step[];
  activeStep: number;
  lastCompletedStep: number;
}

const findLastCompletedStep = (supplier: Supplier): number => {
  if (Object.values(supplier.operationContact).some((x) => x === "")) {
    return 0;
  }

  if (
    Object.values(supplier.finance.personOfContact).some((x) => x === "") ||
    Object.values(supplier.finance.payout).some((x) => x === "")
  ) {
    return 1;
  }

  if (supplier.licenses.length === 0) {
    return 2;
  }

  if (supplier.companyDetails.logo.name === "") {
    return 3;
  }

  if (supplier.companyDetails.description === "") {
    return 4;
  }

  return 5;
};

@Module
export default class SupplierOnboardingModule
  extends VuexModule
  implements StoreInfo
{
  supplier: Supplier | undefined = undefined;
  activeStep = 1;
  lastCompletedStep = 0;
  steps = [
    {
      number: 1,
      title: "step__operational_details",
    },
    {
      number: 2,
      title: "step__finance",
    },
    {
      number: 3,
      title: "step__licences",
    },
    {
      number: 4,
      title: "step__logo",
    },
    {
      number: 5,
      title: "step__company_description",
    },
  ];

  get getSupplierOnboardingActiveStep() {
    return this.activeStep;
  }

  get getSupplierOnboardingLastCompletedStep() {
    return this.lastCompletedStep;
  }

  get getSupplierOnboarding() {
    return this.supplier;
  }

  get getSupplierOnboardingSteps() {
    return this.steps;
  }

  get getSupplierOnboardingProgressPercentage() {
    return (this.lastCompletedStep * 100) / this.steps.length;
  }

  get getSupplierOnboardingNumberOfStepsToGo() {
    return this.steps.length - this.lastCompletedStep;
  }

  @Mutation
  [Mutations.SET_SUPPLIER](payload: Supplier): void {
    this.supplier = payload;
  }

  @Mutation
  [Mutations.SET_ACTIVE_STEP](payload: number): void {
    this.activeStep = payload;
  }

  @Mutation
  [Mutations.SET_LAST_COMPLETED_STEP](payload: number): void {
    this.lastCompletedStep = payload;
  }

  @Action
  [Actions.GOTO_NEXT_STEP]() {
    if (this.activeStep <= this.lastCompletedStep) {
      this.context.commit(Mutations.SET_ACTIVE_STEP, this.activeStep + 1);
      return;
    }

    this.context.commit(Mutations.SET_LAST_COMPLETED_STEP, this.activeStep);
    this.context.commit(Mutations.SET_ACTIVE_STEP, this.activeStep + 1);
  }

  @Action
  [Actions.LOAD_SUPPLIER]() {
    ApiService.get("", "supplier")
      .then(({ data }: { data: Supplier }) => {
        this.context.commit(Mutations.SET_SUPPLIER, data);
        const lastCompletedStep = findLastCompletedStep(data);
        this.context.commit(
          Mutations.SET_LAST_COMPLETED_STEP,
          lastCompletedStep
        );
        this.context.commit(Mutations.SET_ACTIVE_STEP, lastCompletedStep + 1);
      })
      .catch(() => {
        console.log("**** [ERROR] cannot load supplier ****");
      });
  }
}
