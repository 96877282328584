enum Actions {
  // action types
  GOTO_NEXT_STEP = "supplierOnboarding__gotoNextStep",
  LOAD_SUPPLIER = "supplierOnboarding__loadSupplier",
}

enum Mutations {
  // mutation types
  SET_ACTIVE_STEP = "supplierOnboarding__setActiveStep",
  SET_LAST_COMPLETED_STEP = "supplierOnboarding__setSupplierLastCompletedStep",
  SET_SUPPLIER = "supplierOnboarding__setSupplier",
}

export { Actions, Mutations };
