import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import tr from "element-plus/es/locale/lang/tr";
import en from "element-plus/es/locale/lang/en";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", navigator.language.split("-")[0]);
}

import dayjs from "dayjs";
import dayjsTrLocale from "dayjs/locale/tr";
import dayjsEnLocale from "dayjs/locale/en";
dayjs.locale(
  localStorage.getItem("lang") === "en"
    ? { ...dayjsEnLocale, weekStart: 1 }
    : { ...dayjsTrLocale, weekStart: 1 }
);

import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: localStorage.getItem("lang") === "en" ? en : tr,
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDpGjT9ygaW_QY-af26iEUF1Rhs8cMERSY",
    libraries: "places",
  },
});

app.use(autoAnimatePlugin);

app.mount("#app");
