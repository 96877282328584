import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ProductModule from "@/store/modules/ProductModule";
import ProductCaledarModule from "@/store/modules/ProductCalendarModule";
import ProductWizardModule from "@/store/modules/ProductWizardModule";
import SupplierOnboardingdModule from "@/store/modules/SupplierOnboardingModule";
import SupplierModule from "@/store/modules/SupplierModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ProductModule,
    ProductCaledarModule,
    ProductWizardModule,
    SupplierOnboardingdModule,
    SupplierModule,
  },
});

export default store;
