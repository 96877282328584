import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/ProductStoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import type { Product } from "@/core/types/product";
import { AxiosError } from "axios";

export interface StoreInfo {
  product?: Product;
}

@Module
export default class ProductModule extends VuexModule implements StoreInfo {
  product: Product | undefined = undefined;

  get getProduct() {
    return this.product;
  }

  get getProductId() {
    return this.product?.id;
  }

  get getProductStatus() {
    return this.product?.status;
  }

  get getProductTitle() {
    return this.product?.title;
  }

  get getProductPhotos() {
    return this.product ? this.product.photos : [];
  }

  get getProductVideo() {
    return this.product?.video;
  }

  get getProductDeparturePoint() {
    return this.product?.departurePoint;
  }

  get getProductShowcasePrice() {
    return this.product?.showcasePrice;
  }

  get getProductDuration() {
    return this.product?.duration;
  }

  get getProductGroupSize() {
    return this.product?.groupSize;
  }

  get getProductFeatures() {
    return this.product?.features;
  }

  get getProductHighlights() {
    return this.product?.highlights;
  }

  get getProductSummary() {
    return this.product?.description.short;
  }

  get getProductFullDescription() {
    return this.product?.description.full;
  }

  get getProductIncludes() {
    return this.product?.includes;
  }

  get getProductExcludes() {
    return this.product?.excludes;
  }

  get getProductImportants() {
    return this.product?.importants;
  }

  get getProductTheme() {
    return this.product?.theme;
  }

  get getProductTransferDirection() {
    return this.product?.transferDirection;
  }

  get getProductPickUpLocations() {
    return this.product?.pickUp.locations;
  }

  get getProductHotelPickUpRegions() {
    return this.product?.pickUp.hotelRegions;
  }

  get getProductHotelPickUpServiceFee() {
    return this.product?.pickUp.hotelServiceFee;
  }

  get getProductPickUpAirports() {
    return this.product?.pickUp.airports;
  }

  get getProductAirportPickUpServiceFee() {
    return this.product?.pickUp.airportPickUpServiceFee;
  }

  get getProductDropOffLocations() {
    return this.product?.dropOff?.locations;
  }

  get getProductHotelDropOffRegions() {
    return this.product?.dropOff?.hotelRegions;
  }

  get getProductDropOffAirports() {
    return this.product?.dropOff?.airports;
  }

  get getProductItinerary() {
    return this.product?.itinerary;
  }

  get getProductLanguages() {
    return this.product?.languages;
  }

  get getProductCancellation() {
    return this.product?.freeCancellationDuration;
  }

  get getProductExtraServices() {
    return this.product?.extraServices;
  }

  @Mutation
  [Mutations.SET_PRODUCT](payload: Product): void {
    this.product = payload;
  }

  @Mutation
  [Mutations.RESET_PRODUCT](): void {
    this.product = undefined;
  }

  @Action
  [Actions.LOAD_PRODUCT](productID: number) {
    ApiService.get("products", `${productID}`)
      .then(({ data }: { data: Product }) => {
        this.context.commit(Mutations.SET_PRODUCT, data);
      })
      .catch(() => {
        console.log("[ERROR]: Cannot load product");
      });
  }

  @Action
  [Actions.UPDATE_PRODUCT_STATUS]({
    productID,
    status,
  }: {
    productID: number;
    status: Product["status"];
  }) {
    const payload: any = { status };
    return ApiService.update(`products/${productID}`, "status", payload)
      .then(() => {
        this.context.dispatch(Actions.LOAD_PRODUCT, productID);
      })
      .catch(() => {
        console.log("[ERROR]: Cannot update status");
      });
  }

  @Action
  [Actions.UPDATE_TITLE]({
    productID,
    title,
  }: {
    productID: number;
    title: Product["title"];
  }) {
    const payload: any = { title };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "title", payload)
        .then(() => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            title,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_DURATION]({
    productID,
    duration,
  }: {
    productID: number;
    duration: string;
  }) {
    const payload: any = { duration };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "duration", payload)
        .then(() => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            duration,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_GROUP_SIZE]({
    productID,
    groupSize,
  }: {
    productID: number;
    groupSize: number;
  }) {
    const payload: any = { groupSize };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "group-size", payload)
        .then(() => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            groupSize,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_SHOWCASE_PRICE]({
    productID,
    originalPrice,
    description,
    discountRate,
  }: {
    productID: number;
    originalPrice: number;
    description: { tr: string; en: string };
    discountRate?: number;
  }) {
    const payload: any = { description, discountRate, amount: originalPrice };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "showcase-price", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            showcasePrice: data.showcasePrice,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_FEATURE]({
    productID,
    featureID,
  }: {
    productID: number;
    featureID: number;
  }) {
    const payload: any = { feature: featureID };
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`products/${productID}/features`, payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            features: data.features,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_FEATURE]({
    productID,
    featureID,
  }: {
    productID: number;
    featureID: number;
  }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`products/${productID}/features/${featureID}`)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            features: data.features,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_SUMMARY]({
    productID,
    overview,
  }: {
    productID: number;
    overview: { tr: string; en: string };
  }) {
    const payload: any = { overview };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "overview", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            description: {
              ...this.product?.description,
              short: data.description.short,
            },
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_FULL_DESCRIPTION]({
    productID,
    fullDescription,
  }: {
    productID: number;
    fullDescription: { tr: string; en: string };
  }) {
    const payload: any = { fullDescription };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "full-description", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            description: {
              ...this.product?.description,
              full: data.description.full,
            },
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_IMPORTANT_INFORMATIONS]({
    productID,
    importants,
  }: {
    productID: number;
    importants: { tr: string; en: string };
  }) {
    const payload: any = { importants };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "importants", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            importants: data.importants,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_HIGHLIGHTS]({
    productID,
    highlights,
  }: {
    productID: number;
    highlights: { tr: string; en: string }[];
  }) {
    const payload: any = { highlights };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "highlights", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            highlights: data.highlights,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_INCLUDES]({
    productID,
    includes,
  }: {
    productID: number;
    includes: { tr: string; en: string }[];
  }) {
    const payload: any = { includes };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "includes", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            includes: data.includes,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_EXCLUDES]({
    productID,
    excludes,
  }: {
    productID: number;
    excludes: { tr: string; en: string }[];
  }) {
    const payload: any = { excludes };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "excludes", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            excludes: data.excludes,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_ATTRACTIONS]({
    productID,
    attractions,
  }: {
    productID: number;
    attractions: number[];
  }) {
    const payload: any = { attractions };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "attractions", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            attractions: data.attractions,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_LANGUAGES]({
    productID,
    languages,
  }: {
    productID: number;
    languages: string[];
  }) {
    const payload: any = { languages };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "languages", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            languages: data.languages,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_ITINERARY]({
    productID,
    itinerary,
  }: {
    productID: number;
    itinerary: Product["itinerary"];
  }) {
    const payload: any = { itinerary: JSON.stringify(itinerary) };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "itinerary", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            itinerary: data.itinerary,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_CANCELLATION]({
    productID,
    duration,
  }: {
    productID: number;
    duration: number;
  }) {
    const payload: any = { duration };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(
        `products/${productID}`,
        "free-cancellation-duration",
        payload
      )
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...this.product,
            freeCancellationDuration: data.freeCancellationDuration,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_MEETING]({
    productID,
    meeting,
  }: {
    productID: number;
    meeting: any;
  }) {
    const payload: any = { ...meeting };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "meeting", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...data,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_EXTRA_SERVICES]({
    productID,
    extraServices,
  }: {
    productID: number;
    extraServices: any;
  }) {
    const payload: any = { extraServices: extraServices };
    return new Promise<void>((resolve, reject) => {
      ApiService.update(`products/${productID}`, "extra-services", payload)
        .then(({ data }: { data: Product }) => {
          this.context.commit(Mutations.SET_PRODUCT, {
            ...data,
          });
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
}
