enum Actions {
  // action types
  GOTO_NEXT_STEP = "gotoNextStep",
  LOAD_PRODUCT_THEME = "loadProductTheme",
  LOAD_DRAFT_PRODUCT = "loadDraftProduct",
}

enum Mutations {
  // mutation types
  SET_ACTIVE_STEP = "setActiveStep",
  SET_LAST_COMPLETED_STEP = "setLastCompletedStep",
  SET_PRODUCT_ID = "setProductId",
  SET_PRODUCT_THEME = "setProductTheme",
  SET_PRODUCT = "setDraftProduct",
}

export { Actions, Mutations };
