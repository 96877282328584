enum Actions {
  LOAD_PRODUCT = "loadProduct",
  UPDATE_PRODUCT_STATUS = "updateProductStatus",
  UPDATE_TITLE = "updateProductTitle",
  UPDATE_DURATION = "updateProductDuration",
  UPDATE_GROUP_SIZE = "updateProductGroupSize",
  UPDATE_SHOWCASE_PRICE = "updateProductShowcasePrice",
  ADD_FEATURE = "addFeatureToProduct",
  DELETE_FEATURE = "deleteFeatureFromProduct",
  UPDATE_SUMMARY = "updateProductSummary",
  UPDATE_FULL_DESCRIPTION = "updateProductFullDescription",
  UPDATE_IMPORTANT_INFORMATIONS = "updateProductImportantInformations",
  UPDATE_HIGHLIGHTS = "updateProductHighlights",
  UPDATE_INCLUDES = "updateProductIncludes",
  UPDATE_EXCLUDES = "updateProductExcludes",
  UPDATE_ATTRACTIONS = "updateProductAttractions",
  UPDATE_LANGUAGES = "updateProductLanguages",
  UPDATE_ITINERARY = "updateProductItinerary",
  UPDATE_CANCELLATION = "updateCancellation",
  UPDATE_MEETING = "updateMeeting",
  UPDATE_EXTRA_SERVICES = "updateExtraServices",
}

enum Mutations {
  SET_PRODUCT = "setProduct",
  RESET_PRODUCT = "resetProduct",
}

export { Actions, Mutations };
