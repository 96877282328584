import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/SupplierStoreEnum";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import type { Supplier } from "@/core/types/supplier";

export interface StoreInfo {
  supplier: Supplier | undefined;
}

@Module
export default class SupplierModule extends VuexModule implements StoreInfo {
  supplier: Supplier | undefined = undefined;

  get getSupplier() {
    return this.supplier;
  }

  @Mutation
  [Mutations.SET_SUPPLIER](payload: Supplier): void {
    this.supplier = payload;
  }

  @Action
  [Actions.LOAD_SUPPLIER]() {
    ApiService.get("", "supplier")
      .then(({ data }: { data: Supplier }) => {
        this.context.commit(Mutations.SET_SUPPLIER, data);
      })
      .catch(() => {
        console.log("**** [ERROR] cannot load supplier ****");
      });
  }
}
